p,
a,
h2,
pre,
button,
code {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

code,
pre {
    font-size: 8pt;
}
